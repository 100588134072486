import { useContext, type ReactNode } from 'react';

import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import CardMembershipTwoToneIcon from '@mui/icons-material/CardMembershipTwoTone';
import ContactsTwoToneIcon from '@mui/icons-material/ContactsTwoTone';
import GroupAddTwoToneIcon from '@mui/icons-material/GroupAddTwoTone';
import GroupsTwoToneIcon from '@mui/icons-material/GroupsTwoTone';
import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone';
import PeopleOutlineTwoToneIcon from '@mui/icons-material/PeopleOutlineTwoTone';
import PortraitTwoToneIcon from '@mui/icons-material/PortraitTwoTone';
import RateReviewIcon from '@mui/icons-material/RateReview';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import StorefrontIcon from '@mui/icons-material/Storefront';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import UploadTwoToneIcon from '@mui/icons-material/UploadTwoTone';
import HomeIcon from '@mui/icons-material/Home';
import { Business, Group } from '@mui/icons-material';
import { Badge } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import RequestSurveyIcon from '@mui/icons-material/AssignmentTurnedIn';
import Receipt from '@mui/icons-material/Receipt';
import UpdateIcon from '@mui/icons-material/Update';


export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}


const notific=3

export const menuItemsForSuperAdmin: MenuItems[] = [
  {
    heading: 'Dashboard',
    items: [
      {
        name: 'Home',
        link: '/app/dashboard',
        icon: HomeIcon,
      }
    ]
  },
  {
    heading: 'Assessments List',
    items:[
      {
        name: 'Download Assessments',
        icon: ListAltTwoToneIcon,
        link: '/app/assessmentslist'
      },

    ]
  },
  
  // {
  //   heading: 'BUSINESS',
  //   items: [
  //     {
  //       name: 'Surveys',
  //       icon: ContactsTwoToneIcon,
  //       link: '',
  //       items: [
  //         {
  //           name: 'Data Modelling',
  //           icon: ListAltTwoToneIcon,
  //           link: '/app/survey/data-modelling'
  //         },
  //         {
  //           name: 'Data Security',
  //           icon: ListAltTwoToneIcon,
  //           link: '/app/survey/data-security'
  //         },
  //       ]
  //     },
  //     {
  //       name: 'Reviews',
  //       icon: GroupAddTwoToneIcon,
  //       link: '',
  //       items: [
  //         {
  //           name: 'Reviews',
  //           icon: RateReviewIcon,
  //           link: '/app/review/list'
  //         },
  //         {
  //          name: 'New',
  //          icon: AddTwoToneIcon,
  //          link: '/app/lead/new'
  //         }
  //       ]
  //     }
  //   ]
  // },
  {
    heading: 'User Management',
    items:[
      {
        name: 'User Management',
        icon: Group,
        link: '/app/superadmin/usermanagement',
        // badge: '10',
        }
    ]
  },
  { 
    heading: 'Manage Companies',
    items: [
      {
        name: 'Manage Companies',
        icon: BusinessIcon,
        link: '/app/superadmin/companymanagement',
      }
    ]
  },
  {
        heading: 'Admin Management',
        items: [
          {
            name: 'Admin Management',
            icon: ContactsTwoToneIcon,
            link: '',
            items: [
              {
                name: 'Company Admin',
                icon: GroupAddIcon,
                link: '/app/superadmin/companyadminmanagement',
                // badge: '10',
              },
              {
                name: 'Consultant Admin',
                icon: AdminPanelSettingsIcon,
                link: '/app/superadmin/consultantadminmanagement',
                // badge: '10',
              },
            ]
          },]
  },
  // {
  //   heading: 'Company Admin Management',
  //   items:[
  //     {
  //       name: 'Company Admin',
  //       icon: GroupAddIcon,
  //       link: '/app/superadmin/companyadminmanagement',
  //       // badge: '10',
  //       }
  //   ]
  // },
  // {
  //   heading: 'Consultant Admin Management',
  //   items:[
  //     {
  //       name: 'Consultant Admin',
  //       icon: AdminPanelSettingsIcon,
  //       link: '/app/superadmin/consultantadminmanagement',
  //       // badge: '10',
  //       }
  //   ]
  // },
  {
    heading: 'Requested Surveys Status',
    items:[
      {
        name: 'Requested Surveys Status',
        icon: UpdateIcon,
        link: '/app/requestedsurveysstatus'
      },

    ]
  }, 
  {
    heading: 'MANAGE',
    items: [
      {
        name: 'Settings',
        icon: SettingsTwoToneIcon,
        link: '/app/settings'
      }
    ]
  },
  {
    heading: 'Support Request',
    items: [
      {
        name: 'Support Request',
        icon: HelpOutlineIcon,
        link: '/app/supportrequest'
      }
    ]
  }
];

// export const menuItemsForSuperAdmin: MenuItems[] = [
//   {
//     heading: 'Dashboard',
//     items: [
//       {
//         name: 'Home',
//         link: '/app/dashboard',
//         icon: HomeIcon,
//       }
//     ]
//   },
//   {
//     heading: 'Assessments List',
//     items:[
//       {
//         name: 'Download Assessments',
//         icon: ListAltTwoToneIcon,
//         link: '/app/assessmentslist'
//       },

//     ]
//   },
//   // {
//   //   heading: 'BUSINESS',
//   //   items: [
//   //     {
//   //       name: 'Surveys',
//   //       icon: ContactsTwoToneIcon,
//   //       link: '',
//   //       items: [
//   //         {
//   //           name: 'Data Modelling',
//   //           icon: ListAltTwoToneIcon,
//   //           link: '/app/survey/data-modelling'
//   //         },
//   //         {
//   //           name: 'Data Security',
//   //           icon: ListAltTwoToneIcon,
//   //           link: '/app/survey/data-security'
//   //         },
//   //       ]
//   //     },
//   //     {
//   //       name: 'Reviews',
//   //       icon: GroupAddTwoToneIcon,
//   //       link: '',
//   //       items: [
//   //         {
//   //           name: 'Reviews',
//   //           icon: RateReviewIcon,
//   //           link: '/app/review/list'
//   //         },
//   //         {
//   //          name: 'New',
//   //          icon: AddTwoToneIcon,
//   //          link: '/app/lead/new'
//   //         }
//   //       ]
//   //     }
//   //   ]
//   // },
//   {
//     heading: 'User Management',
//     items:[
//       {
//         name: 'User Management',
//         icon: Group,
//         link: '/app/superadmin/usermanagement',
//         // badge: '10',
//         }
//     ]
//   },
//   {
//     heading: 'Admin Management',
//     items: [
//       {
//         name: 'Admin Management',
//         icon: ContactsTwoToneIcon,
//         link: '',
//         items: [
//           {
//             name: 'Company Admin',
//             icon: GroupAddIcon,
//             link: '/app/superadmin/companyadminmanagement',
//             // badge: '10',
//           },
//           {
//             name: 'Consultant Admin',
//             icon: AdminPanelSettingsIcon,
//             link: '/app/superadmin/consultantadminmanagement',
//             // badge: '10',
//           },
//         ]
//       },
//       // {
//       //   name: 'Reviews',
//       //   icon: GroupAddTwoToneIcon,
//       //   link: '',
//       //   items: [
//       //     {
//       //       name: 'Reviews',
//       //       icon: RateReviewIcon,
//       //       link: '/app/review/list'
//       //     },
//       //     {
//       //      name: 'New',
//       //      icon: AddTwoToneIcon,
//       //      link: '/app/lead/new'
//       //     }
//       //   ]
//       // }
//     ]
//   },
//   { 
//     heading: 'Manage Companies',
//     items: [
//       {
//         name: 'Manage Companies',
//         icon: BusinessIcon,
//         link: '/app/superadmin/companymanagement',
//       }
//     ]
//   },
//   // {
//   //   heading: 'Company Admin Management',
//   //   items:[
//   //     {
//   //       name: 'Company Admin',
//   //       icon: GroupAddIcon,
//   //       link: '/app/superadmin/companyadminmanagement',
//   //       // badge: '10',
//   //       }
//   //   ]
//   // },
//   // {
//   //   heading: 'Consultant Admin Management',
//   //   items:[
//   //     {
//   //       name: 'Consultant Admin',
//   //       icon: AdminPanelSettingsIcon,
//   //       link: '/app/superadmin/consultantadminmanagement',
//   //       // badge: '10',
//   //       }
//   //   ]
//   // },
//   {
//     heading: 'Requested Surveys Status',
//     items:[
//       {
//         name: 'Requested Surveys Status',
//         icon: UpdateIcon,
//         link: '/app/requestedsurveysstatus'
//       },

//     ]
//   }, 
//   {
//     heading: 'MANAGE',
//     items: [
//       {
//         name: 'Settings',
//         icon: SettingsTwoToneIcon,
//         link: '/app/settings'
//       }
//     ]
//   },
//   {
//     heading: 'Support Request',
//     items: [
//       {
//         name: 'Support Request',
//         icon: HelpOutlineIcon,
//         link: '/app/supportrequest'
//       }
//     ]
//   }
// ];

export const menuItemsForAdmin: MenuItems[] = [
  {
    heading: 'Dashboard',
    items: [
      {
        name: 'Home',
        link: '/app/dashboard',
        icon: HomeIcon,
      }
    ]
  },
  {
    heading: 'Assessments List',
    items:[
      {
        name: 'Download Assessments',
        icon: ListAltTwoToneIcon,
        link: '/app/assessmentslist'
      },

    ]
  },
  {
    heading: 'MANAGE',
    items: [
      {
        name: 'Settings',
        icon: SettingsTwoToneIcon,
        link: '/app/settings'
      }
    ]
  },
  {
    heading: 'Support Request',
    items: [
      {
        name: 'Support Request',
        icon: HelpOutlineIcon,
        link: '/app/supportrequest'
      }
    ]
  }
];

export const menuItemsForNonAdminUser: MenuItems[] = [
  {
    heading: 'Dashboard',
    items: [
      {
        name: 'Main',
        icon: StorefrontTwoToneIcon,
        link: '/app/dashboard'
      }
    ]
  },
  {
    heading: 'MANAGEMENT',
    items: [
      {
        name: 'Company Profiles',
        icon: ListAltTwoToneIcon,
        link: '/app/cards/business'
      },
      {
        name: 'Member Profiles',
        icon: ListAltTwoToneIcon,
        link: '/app/cards/personal'
      },
      {
        name: 'My Cards',
        icon: CardMembershipTwoToneIcon,
        link: '/app/my-cards'
      }
    ]
  },
  {
    heading: 'BUSINESS',
    items: [
      {
        name: 'Contact',
        icon: ContactsTwoToneIcon,
        link: '',
        items: [
          {
            name: 'List',
            icon: ListAltTwoToneIcon,
            link: '/app/contact/list'
          },
          {
            name: 'New',
            icon: AddTwoToneIcon,
            link: '/app/contact/new'
          },
          {
            name: 'Upload',
            icon: UploadTwoToneIcon,
            link: '/app/contact/upload'
          }
        ]
      },
      {
        name: 'Lead',
        icon: GroupAddTwoToneIcon,
        link: '',
        items: [
          {
            name: 'List',
            icon: ListAltTwoToneIcon,
            link: '/app/lead/list'
          }
        ]
      },
      {
        name: 'Reviews',
        icon: GroupAddTwoToneIcon,
        link: '',
        items: [
          {
            name: 'Reviews',
            icon: RateReviewIcon,
            link: '/app/review/list'
          }
          //{
          //  name: 'New',
          //  icon: AddTwoToneIcon,
          //  link: '/app/lead/new'
          //}
        ]
      }
    ]
  }
];

export const menuItemsForIndividualUser: MenuItems[] = [
  {
    heading: 'Dashboard',
    items: [
      {
        name: 'Home',
        icon: HomeIcon,
        link: '/app/dashboard'
      }
    ]
  },
  {
    heading: 'Assessments List',
    items:[
      {
        name: 'Download Assessments',
        icon: ListAltTwoToneIcon,
        link: '/app/assessmentslist'
      },

    ]
  },
  // {
  //   heading: 'Profiles',
  //   items: [
  //     {
  //       name: 'Member Profiles',
  //       icon: PortraitTwoToneIcon,
  //       link: '/app/cards/personal'
  //     }, 
  //     // {
  //     //   name: 'My Cards',
  //     //   icon: CardMembershipTwoToneIcon,
  //     //   link: '/app/my-cards'
  //     // }
  //   ]
  // },
  {
    heading: 'Survey Request',
    items:[
      {
        name: 'Request Survey',
        icon: RequestSurveyIcon,
        link: '/app/requestsurvey'
      },

    ]
  },
  {
    heading: 'MANAGE',
    items: [
      // {
      //   name: 'Contact',
      //   icon: ContactsTwoToneIcon,
      //   link: '',
      //   items: [
      //     {
      //       name: 'List',
      //       icon: ListAltTwoToneIcon,
      //       link: '/app/contact/list'
      //     },
      //     {
      //       name: 'New',
      //       icon: AddTwoToneIcon,
      //       link: '/app/contact/new'
      //     },
      //     {
      //       name: 'Upload',
      //       icon: UploadTwoToneIcon,
      //       link: '/app/contact/upload'
      //     }
      //   ]
      // },
      // {
      //   name: 'Lead',
      //   icon: GroupAddTwoToneIcon,
      //   link: '',
      //   items: [
      //     {
      //       name: 'List',
      //       icon: ListAltTwoToneIcon,
      //       link: '/app/lead/list'
      //     }
      //   ]
      // },
      {
        name: 'Settings',
        icon: SettingsTwoToneIcon,
        link: '/app/settings'
      }
      //   {
      //     name: 'Reviews',
      //     icon: GroupAddTwoToneIcon,
      //     link: '',
      //     items: [
      //         {
      //             name: 'Reviews',
      //             icon: RateReviewIcon,
      //             link: '/app/review/list'
      //         },
      //         //{
      //         //  name: 'New',
      //         //  icon: AddTwoToneIcon,
      //         //  link: '/app/lead/new'
      //         //}
      //     ]
      // },
      
    ],
    
  },
  {
    heading: 'Support Request',
    items: [
      {
        name: 'Support Request',
        icon: HelpOutlineIcon,
        link: '/app/supportrequest'
      }
    ]
  }
];

export const menuItemsForCompanyAdmin: MenuItems[] = [
  {
    heading: 'Dashboard',
    items: [
      {
        name: 'Home',
        link: '/app/dashboard',
        icon: HomeIcon,
      }
    ]
    },
  {
    heading: 'Manage Users',
    items: [
      {
        name: 'Manage Users',
        link: '/app/company-admin/manage-users',
        icon: ManageAccountsIcon,
      }
     
    ]
  },
  {
    heading: 'Add Users',
    items: [
      {
        name: 'Add Users',
        icon: GroupAddIcon,
        link: '/app/company-admin/add-users', 
      }
    ]
  },
  {
    heading: 'Survey Request',
    items:[
      {
        name: 'Request Survey',
        icon: RequestSurveyIcon,
        link: '/app/requestsurvey'
      },

    ]
  },   
  {
    heading: 'Settings',
    items:[
      {
        name: 'Settings',
        icon: SettingsTwoToneIcon,
        link: '/app/settings'
      }
    ]
  },
  {
    heading: 'Support Request',
    items: [
      {
        name: 'Support Request',
        icon: HelpOutlineIcon,
        link: '/app/supportrequest'
      }
    ]
  }
];


export const menuItemsForConsultingAdmin: MenuItems[] = [
  {
    heading: 'Dashboard',
    items: [
      {
        name: 'Home',
        icon: HomeIcon,
        link: '/app/dashboard',
      }
    ]
  },
  { 
    heading: 'Manage Companies',
    items: [
      {
        name: 'Manage Companies',
        icon: BusinessIcon,
        link: '/app/consulting-admin/companies',
      }
    ]
  },
  {
    heading: 'Add Company Admin',
    items: [
      {
        name: 'Add Company Admin',
        icon: GroupAddIcon,
        link: '/app/consulting-admin/add-company-admin', 
      }
    ]
  }, 
  {
    heading: 'Survey Request',
    items:[
      {
        name: 'Request Survey',
        icon: RequestSurveyIcon,
        link: '/app/requestsurvey'
      },

    ]
  },  
  {
    heading: 'Settings',
    items:[
      {
        name: 'Settings',
        icon: SettingsTwoToneIcon,
        link: '/app/settings'
      }
    ]
  },
  {
    heading: 'Support Request',
    items: [
      {
        name: 'Support Request',
        icon: HelpOutlineIcon,
        link: '/app/supportrequest'
      }
    ]
  }
];


