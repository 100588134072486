import axios, { AxiosResponse } from 'axios';
import { Dictionary } from 'lodash';
import { ApiUrls } from '../endpoints';
import { ActiveTracker, BuildSurvey, Companies, FactEval, Survey, SurveyGroup, SurveyStatusResponse, Tracker, User, UserContext, UserRoles, UsersApproval } from './response-models';
import createAuth0Client from '@auth0/auth0-spa-js';
import { auth0Config } from 'src/config';

export const getAllSurveyGroups = async (): Promise<
  AxiosResponse<SurveyGroup[]>
> => {
  const dataUrl =
    ApiUrls.surveyEndpoint + '/survey-groups';
  const res = await axios.get(dataUrl);
  if (res && res.data) {
    (res.data as SurveyGroup[]).forEach(o => {
      o.surveys.forEach(s => {
        if (!s.surveyGroup) {
          s.surveyGroup = { ...o };
        }
      })
    })
  }

  return res;
};

export const getSurveyDetailById = (surveyId: number): Promise<
  AxiosResponse<Survey>
> => {
  const dataUrl =
    ApiUrls.surveyEndpoint + '/survey-detail/id/' + surveyId;
  return axios.get(dataUrl);
};

export const startEval = (surveyId: number): Promise<
  AxiosResponse<Tracker>
> => {
  const dataUrl =
    ApiUrls.surveyEndpoint + '/start-tracker';
  return axios.post(dataUrl, { surveyId });
};

export const updateEval = (tracker: Tracker): Promise<
  AxiosResponse<Tracker>
> => {
  const dataUrl =
    ApiUrls.surveyEndpoint + '/update-tracker';
  return axios.post(dataUrl, tracker);
};

export const endEval = (trackerID: number): Promise<
  AxiosResponse<Tracker>
> => {
  const dataUrl =
    ApiUrls.surveyEndpoint + '/end-tracker';
  return axios.post(dataUrl, { trackerID });
};



export const saveEvalAnswer = (fact: FactEval): Promise<
  AxiosResponse<Tracker>
> => {
  const dataUrl =
    ApiUrls.surveyEndpoint + '/eval-answer';
  return axios.post(dataUrl, fact);
};

export const deleteEvalAnswer = (fact: FactEval): Promise<
  AxiosResponse<Tracker>
> => {
  const dataUrl =
    ApiUrls.surveyEndpoint + '/delete-eval-answer';
  return axios.post(dataUrl, fact);
};
export const getActiveTracker = (): Promise<
  AxiosResponse<ActiveTracker>
> => {
  const dataUrl =
    ApiUrls.surveyEndpoint + '/get-active-tracker';
  return axios.get(dataUrl);
};
export const getTrackers = (): Promise<
  AxiosResponse<Tracker[]>
> => {
  const dataUrl =
    ApiUrls.surveyEndpoint + '/trackers';
  return axios.get(dataUrl);
};
export const getUserContext = (): Promise<
  AxiosResponse<UserContext>
> => {
  const dataUrl =
    ApiUrls.surveyEndpoint + '/user-context';
  return axios.get(dataUrl);
};

export const downloadSurveyPDF = async (userEmail: string, surveyName: string, userId: number): Promise<Blob | null> => {
  try {
    const username = userEmail.substring(0, userEmail.indexOf('@'));
    const pdfBlobUrl = ApiUrls.pdfBlobEndpoint + `${userId}/` + `${username} - ${surveyName}.pdf`;
    const response = await fetch(pdfBlobUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
      },
    });

    if (response.ok) {
      const pdfBlob = await response.blob();
      return pdfBlob;
    }

    const responseBody = await response.json();
    if (responseBody.message === 'User ID does not exist or has no associated PDFs') {
      console.error(responseBody.message);
      return null;
    }

    throw new Error('Failed to download survey PDF.');
  } catch (error) {
    console.error('Error downloading survey PDF:', error);
    throw error;
  }
};

// function generateRandomPassword(length) {
//   const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";
//   let password = "";
//   for (let i = 0; i < length; i++) {
//     const randomIndex = Math.floor(Math.random() * charset.length);
//     password += charset[randomIndex];
//   }
//   return password;
// }

// export async function createUser(userEmail) {
//   const managementApiEndpoint = ApiUrls.CreateUserEndpoint;
//   const userPassword = generateRandomPassword(12); 
  
//   try {
//     const accessToken = await getAccessToken();
//     const response = await axios.post(managementApiEndpoint, {
//       email: userEmail,
//       password: userPassword,
//       connection: 'Username-Password-Authentication'
//     },{
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//       }
//     });

//     return { data: response.data, password: userPassword };
//   } catch (error) {
//     console.error('Error creating user:', error);
//     throw error;
//   }
// }


// async function getAccessToken() {
//   try {
//     const auth0Domain=process.env.REACT_APP_AUTH0_DOMAIN+'/oauth/token'
//     const response = await axios.post(
//       auth0Domain,
//       {
//         client_id: 'h8b7OOpv46RbuFcNysb6ZNsJ55kdj9fa',
//         client_secret: 'I0a5413Lq1FMBMinQiTPQAEQazdyltxb2lH41Rqj_okjaiO3ECmhLkyyFRNuukTC',
//         audience: process.env.REACT_APP_AUTH0_DOMAIN+'/api/v2/',
//         grant_type: 'client_credentials'
//       },
//       {
//         headers: {
//           'Content-Type': 'application/json'
//         }
//       }
//     );

//     return response.data.access_token;
//   } catch (error) {
//     console.error('Error fetching token:', error);
//     throw error;
//   }
// }

export const getUserByID = async (userID: number): Promise<User> => {
  try {
    const dataUrl=ApiUrls.UsersDataNodeEndpoint + '/GetUserByID';
    const response = await axios.get(dataUrl,{params: {UserID:userID}});
    return response.data;
  } catch (error) {
    console.error('Error fetching users data:', error);
    throw error;
  }
};

export const updateUser = async (data: Partial<User>): Promise<any> => {
  try {
    const dataUrl=ApiUrls.UsersDataNodeEndpoint + '/updateUserById'
    const response=await axios.post(dataUrl, data);
    return response.data;
  } catch (error) {
    console.error('Error updating user data:', error);
    throw error;
  }
};


export const getUsersApproval = async (): Promise<AxiosResponse<UsersApproval[]>> => {
  try {
    const dataUrl=ApiUrls.UsersDataNodeEndpoint + '/getUserApproval';
    const response = await axios.get(dataUrl);
    return response.data;
  } catch (error) {
    console.error('Error fetching users data:', error);
    throw error;
  }
};

export const getIndividualUsers = async (): Promise<AxiosResponse<UsersApproval[]>> => {
  try {
    const dataUrl=ApiUrls.UsersDataNodeEndpoint + '/getIndividualUsers';
    const response = await axios.get(dataUrl);
    return response.data;
  } catch (error) {
    console.error('Error fetching users data:', error);
    throw error;
  }
};

export const getUsersWithCompany = async (): Promise<AxiosResponse<UsersApproval[]>> => {
  try {
    const dataUrl=ApiUrls.UsersDataNodeEndpoint + '/getUsersWithCompany';
    const response = await axios.get(dataUrl);
    return response.data;
  } catch (error) {
    console.error('Error fetching users data:', error);
    throw error;
  }
};

export const getUsersWithRoleID = async (): Promise<AxiosResponse<UsersApproval[]>> => {
  try {
    const dataUrl=ApiUrls.UsersDataNodeEndpoint + '/getUsersWithRoleID';
    const response = await axios.get(dataUrl);
    return response.data;
  } catch (error) {
    console.error('Error fetching users data:', error);
    throw error;
  }
};


export const getUnapprovedUserSignups = async (): Promise<AxiosResponse<UsersApproval[]>> => {
  try {
    const dataUrl=ApiUrls.UsersDataNodeEndpoint + '/getUnapprovedUserSignups';
    const response = await axios.get(dataUrl);
    return response.data;
  } catch (error) {
    console.error('Error fetching users data:', error);
    throw error;
  }
};

export const getApprovedCompanyAdminsBySuperAdmin = async (): Promise<AxiosResponse<UsersApproval[]>> => {
  try {
    const dataUrl=ApiUrls.UsersDataNodeEndpoint + '/getApprovedCompanyAdminsBySuperAdmin';
    const response = await axios.get(dataUrl);
    return response.data;
  } catch (error) {
    console.error('Error fetching users data:', error);
    throw error;
  }
};

export const getApprovedConsultantAdminsBySuperAdmin = async (): Promise<AxiosResponse<UsersApproval[]>> => {
  try {
    const dataUrl=ApiUrls.UsersDataNodeEndpoint + '/getApprovedConsultantAdminsBySuperAdmin';
    const response = await axios.get(dataUrl);
    return response.data;
  } catch (error) {
    console.error('Error fetching users data:', error);
    throw error;
  }
};

export const getCompanyAdminsByConsultant = async (consultantID: number): Promise<AxiosResponse<UsersApproval[]>> => {
  try {
    const dataUrl = `${ApiUrls.UsersDataNodeEndpoint}/getCompanyAdminsByConsultant?consultantID=${consultantID}`;
    const response = await axios.get(dataUrl);
    return response.data;
  } catch (error) {
    console.error('Error fetching users data:', error);
    throw error;
  }
};

export const getCompanyUsersByConsultant = async (consultantID: number): Promise<AxiosResponse<UsersApproval[]>> => {
  try {
    const dataUrl = `${ApiUrls.UsersDataNodeEndpoint}/getCompanyUsersByConsultant?consultantID=${consultantID}`;
    const response = await axios.get(dataUrl);
    return response.data;
  } catch (error) {
    console.error('Error fetching company by consultant:', error);
    throw error;
  }
};

export const getCompaniesByConsultant = async (consultantID: number): Promise<AxiosResponse<Companies[]>> => {
  try {
    const dataUrl = `${ApiUrls.UsersDataNodeEndpoint}/getCompaniesByConsultant?consultantID=${consultantID}`;
    const response = await axios.get(dataUrl);
    return response.data;
  } catch (error) {
    console.error('Error fetching companies by consultant:', error);
    throw error;
  }
};

export const getCompanyUsersByCompanyAdmin = async (adminDomain: string): Promise<AxiosResponse<UsersApproval[]>> => {
  try {
    const dataUrl = `${ApiUrls.UsersDataNodeEndpoint}/getCompanyUsersByCompanyAdmin?adminDomain=${adminDomain}`;
    const response = await axios.get(dataUrl);
    return response.data;
  } catch (error) {
    console.error('Error fetching company users by company admin:', error);
    throw error;
  }
};

export const getCompanies = async (): Promise<AxiosResponse<Companies[]>> => {
  try {
    const dataUrl=ApiUrls.UsersDataNodeEndpoint + '/getCompanies';
    const response = await axios.get(dataUrl);
    return response.data;
  } catch (error) {
    console.error('Error fetching companies data:', error);
    throw error;
  }
};

export const getRoles = async (): Promise<AxiosResponse<UserRoles[]>> => {
  try {
    const getUsersData =ApiUrls.UsersDataNodeEndpoint + '/getRoles';
    const response = await axios.get(getUsersData);
    return response.data;
  } catch (error) {
    console.error('Error fetching roles:', error);
    throw error;
  }
};


export const getRequestSurveys = async (): Promise<AxiosResponse<BuildSurvey[]>> => {
  try {
    const getRequestSurveysUrl =ApiUrls.UsersDataNodeEndpoint + '/getAllBuildSurveys';
    const response = await axios.get(getRequestSurveysUrl);
    return response.data;
  } catch (error) {
    console.error('Error fetching requested surveys:', error);
    throw error;
  }
};
export const updateUserApproval = async (data: Partial<UsersApproval>): Promise<void> => {
  try {
    const dataUrl=ApiUrls.UsersDataNodeEndpoint + '/updateUserApproval'
    await axios.post(dataUrl, data);
  } catch (error) {
    console.error('Error updating user approval:', error);
    throw error;
  }
};

export const createConsultantAdmin = async (userEmail: string,TemporaryPassword : string): Promise<void> => {
  try {
    const dataUrl=ApiUrls.UsersDataNodeEndpoint + '/createConsultantAdmin'
    await axios.post(dataUrl, {UserEmail: userEmail,TemporaryPassword: TemporaryPassword});
  } catch (error) {
    console.error('Error creating consultant admin:', error);
    throw error;
  }
};

export const createCompanyAdmin = async (userEmail: string,TemporaryPassword : string): Promise<void> => {
  try {
    const dataUrl=ApiUrls.UsersDataNodeEndpoint + '/createCompanyAdmin'
    await axios.post(dataUrl, {UserEmail: userEmail,TemporaryPassword: TemporaryPassword});
  } catch (error) {
    console.error('Error creating company admin:', error);
    throw error;
  }
};

export const createCompanyUser = async (userEmail: string,UserCompanyName: string,TemporaryPassword : string): Promise<void> => {
  try {
    const dataUrl=ApiUrls.UsersDataNodeEndpoint + '/createCompanyUser'
    await axios.post(dataUrl, {UserEmail: userEmail,UserCompanyName: UserCompanyName,TemporaryPassword : TemporaryPassword});
  } catch (error) {
    console.error('Error creating company user:', error);
    throw error;
  }
};

export const createNonCompanyUser = async (userEmail: string,TemporaryPassword : string): Promise<void> => {
  try {
    const dataUrl=ApiUrls.UsersDataNodeEndpoint + '/createNonCompanyUser'
    const payload = {
      UserEmail: userEmail,
      TemporaryPassword: TemporaryPassword
    };
    await axios.post(dataUrl, payload);
  } catch (error) {
    console.error('Error creating non company user :', error);
    throw error;
  }
};

export const createCompanyAdminByConsultantAdmin = async (userEmail: string,ConsultantID: number,TemporaryPassword : string): Promise<void> => {
  try {
    const dataUrl=ApiUrls.UsersDataNodeEndpoint + '/AddCompanyAdminByConsultantAdmin'
    const payload = {
      UserEmail: userEmail,
      ConsultantID: ConsultantID,
      TemporaryPassword: TemporaryPassword
    };
    await axios.post(dataUrl, payload);
  } catch (error) {
    console.error('Error creating company admin by consultant admin:', error);
    throw error;
  }
};

export const createCompanyUserByConsultantAdmin = async (userEmail: string,UserCompanyName: string,ConsultantID: number,TemporaryPassword : string): Promise<void> => {
  try {
    const dataUrl=ApiUrls.UsersDataNodeEndpoint + '/AddCompanyUserByConsultantAdmin'
    const payload = {
      UserEmail: userEmail,
      UserCompanyName: UserCompanyName,
      ConsultantID: ConsultantID,
      TemporaryPassword: TemporaryPassword
    };
    await axios.post(dataUrl, payload);
  } catch (error) {
    console.error('Error creating company user by consultant admin:', error);
    throw error;
  }
};

export const CreateCompanySurveyApproval = async (data: Partial<UsersApproval>): Promise<any> => {
  try {
    const dataUrl=ApiUrls.UsersDataNodeEndpoint + '/insertRecordsByCompany'
    return await axios.post(dataUrl, data);
  } catch (error) {
    console.error('Error creating company survey approval:', error);
    throw error;
  }
};

export const CreateUserSurveyApproval = async (data: Partial<UsersApproval>): Promise<any> => {
  try {
    const dataUrl=ApiUrls.UsersDataNodeEndpoint + '/insertRecordsByUserid'
    return await axios.post(dataUrl, data);
  } catch (error) {
    console.error('Error creating user survey approval:', error);
    throw error;
  }
};

export const CreateCompanies = async (data: Partial<Companies>): Promise<any> => {
  try {
    const dataUrl=ApiUrls.UsersDataNodeEndpoint + '/createCompany'
    return await axios.post(dataUrl, data);
  } catch (error) {
    console.error('Error creating companies:', error);
    throw error;
  }
};

export const getAssignedSurveysForCompany = async (CompanyID: number): Promise<any> => {
  try {
     const dataUrl=ApiUrls.UsersDataNodeEndpoint + '/getAllSurveyByCompanyId';
    const response = await axios.get(dataUrl, {params: {CompanyID}});
    return response.data;
  } catch (error) {
    console.error('Error fetching assigned surveys for company:', error);
    throw error;
  }
};

export const getAssignedSurveysForUser = async (UserID: number): Promise<any> => {
  try {
     const dataUrl=ApiUrls.UsersDataNodeEndpoint + '/getAllSurveyByUserId';
    const response = await axios.get(dataUrl, {params: {UserID}});
    return response.data;
  } catch (error) {
    console.error('Error fetching assigned surveys for user:', error);
    throw error;
  }
};

export const getSurveyStatus = async (UserID: number,SurveyID: number): Promise<SurveyStatusResponse> => {
  try {
     const dataUrl=ApiUrls.UsersDataNodeEndpoint + '/getSurveyStatus';
    const response = await axios.get(dataUrl, {params: {UserID,SurveyID}});
    return response.data;
  } catch (error) {
    console.error('Error fetching surveys status:', error);
    throw error;
  }
};

export const createUser = async (userEmail: string): Promise<any> => {
  try {
    const dataUrl=ApiUrls.UsersDataNodeEndpoint + '/createUser';
    const response = await axios.post(dataUrl,{userEmail : userEmail});
    return response.data;
  } catch (error) {
    console.error('Error fetching users data:', error);
    throw error;
  }
};

export const deleteUserFromAuth0 = async (AuthId: string): Promise<any> => {
  try {
    const dataUrl=ApiUrls.UsersDataNodeEndpoint + '/deleteUserfromAuth0';
    const response = await axios.post(dataUrl,{AuthId : AuthId});
    return response.data;
  } catch (error) {
    console.error('Error fetching users data:', error);
    throw error;
  }
};

export const removeAssignedSurvey = async (approvalId: number,surveyName: string): Promise<any> => {
  try {
    const dataUrl=ApiUrls.UsersDataNodeEndpoint + '/deleteAssignedSurveyFromUser';
    const response = await axios.post(dataUrl, {ApprovalID : approvalId,surveyName : surveyName});
    if (response.status === 200) {
      return "Record deleted successfully."
    } else {
      throw new Error('Failed to delete record.');
    }
  } catch (error) {
    console.error('Error deleting record:', error);
    throw error; 
  }
};


type EntityType = 'user' | 'company';

export const uploadFileToAzure = async (files: File, entityId: number, entityType: EntityType): Promise<string[]> => {
  const dataUrl = ApiUrls.UsersDataNodeEndpoint + '/uploadPdf';
  const formData = new FormData();
  formData.append('files', files);

  if (entityType === 'user') {
    formData.append('userId', entityId.toString());
  } else if (entityType === 'company') {
    formData.append('companyId', entityId.toString());
  }

  try {
    const response = await axios.post(dataUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (response.status === 200) {
      return response.data.blobUrls;
    } else {
      throw new Error('File upload failed.');
    }
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};

export const uploadProfileImageToAzure = async (file: File, userId: number): Promise<string> => {
  const dataUrl = ApiUrls.UsersDataNodeEndpoint + '/uploadProfileImage';
  const formData = new FormData();
  formData.append('file', file);
  formData.append('userId', userId.toString());

  try {
    const response = await axios.post(dataUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (response.status === 200  && response.data.success) {
      return response.data.imageUrl;
    } else {
      throw new Error('Profile image upload failed.');
    }
  } catch (error) {
    console.error('Error uploading profile image:', error);
    throw error;
  }
};

export const createSurvey = async (surveyData: { SurveyProductName: string; SurveyProductCategory: string; SurveyName: string, AdditionalContext: string }) => {
  try {
    const dataUrl=ApiUrls.UsersDataNodeEndpoint + '/buildSurveyRequest'
    const response = await axios.post(dataUrl, surveyData); 
    return response.data;
  } catch (error) {
    console.error('Error creating survey:', error);
    throw error;
  }
};


export const deleteBuildSurvey = async (BuildSurveyID:number, SurveyName: string,Status: string) => {
  try {
    const dataUrl=ApiUrls.UsersDataNodeEndpoint + '/deleteBuildSurvey';
    console.log(dataUrl)
    const response = await axios.post(dataUrl,{BuildSurveyID : BuildSurveyID,SurveyName : SurveyName,Status : Status}); 
    return response.data;
  } catch (error) {
    console.error('Error deleting survey:', error);
    throw error;
  }
};

export const updateSurveyStatus = async (BuildSurveyID:number,Status: string) => {
  try {
    const dataUrl=ApiUrls.UsersDataNodeEndpoint + '/updateSurveyStatus'
    const response=await axios.post(dataUrl, {BuildSurveyID : BuildSurveyID,Status : Status});
    return response.data;
  } catch (error) {
    console.error('Error updating survey status:', error);
    throw error;
  }
};

export const getAllReportsFromBlob = async (UserID: number): Promise<any> => {
  try {
     const dataUrl=ApiUrls.UsersDataNodeEndpoint + '/getPdfsByUserId';
    const response = await axios.get(dataUrl, {params: {UserID}});
    return response.data;
  } catch (error) {
    console.error('Error fetching reports from blob:', error);
    throw error;
  }
};

export const getProfileImageUrl = async (userId: number): Promise<any> => {
  try {
     const dataUrl=ApiUrls.UsersDataNodeEndpoint + '/getProfileImageUrl';
    const response = await axios.get(dataUrl,{params: {userId}});
    return response.data;
  } catch (error) {
    console.error('Error fetching reports from blob:', error);
    throw error;
  }
};

export const resetActiveTrackerAndSurvey = async (TrackerID : number): Promise<any> => {
  try {
     const dataUrl=ApiUrls.UsersDataNodeEndpoint + '/deleteTrackerId';
    const response = await axios.post(dataUrl,{TrackerID:TrackerID});
    return response.data;
  } catch (error) {
    console.error('Error fetching assigned surveys:', error);
    throw error;
  }
};


