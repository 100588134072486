import { FC, useState, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

import { useSnackbar } from 'notistack';
import Text from 'src/ui-bloom/components/Text';

import {
  Typography,
  Box,
  Grid,
  Divider,
  IconButton,
  Button,
  Card,
  Rating,
  FormControl,
  styled,
  TextField,
  Tabs,
  Tab,
  Select,
  Slide,
  MenuItem,
  useTheme,
  useMediaQuery
} from '@mui/material';
import AddShoppingCartTwoToneIcon from '@mui/icons-material/AddShoppingCartTwoTone';
import { useTranslation } from 'react-i18next';
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone';
import ChevronLeftTwoToneIcon from '@mui/icons-material/ChevronLeftTwoTone';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from 'swiper';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import ReviewsTab from './ReviewsTab';
import AdditionalInfoTab from './AdditionalInfoTab';
import { Survey } from 'src/services/apiService/response-models';
import cdnService from 'src/services/cdnService';

interface ProductBodyProps {
  product: Survey;
}

const SwipeIndicator = styled(IconButton)(
  ({ theme }) => `
    @media (max-width: ${theme.breakpoints.values.sm}px) {
        display: none;
    }
    transition: ${theme.transitions.create(['background', 'color'])};
    color: ${theme.colors.primary.main};
    background: ${theme.colors.alpha.white[100]};
    position: absolute;
    width: ${theme.spacing(5)};
    height: ${theme.spacing(5)};
    top: 50%;
    margin-top: ${theme.spacing(-1.5)};
    border-radius: 100px;
    z-index: 5;

    &:hover {
      background: ${theme.colors.alpha.white[100]};
      color: ${theme.colors.alpha.black[100]};
    }

    &.MuiSwipe-left {
      left: ${theme.spacing(0.5)};
    }
    
    &.MuiSwipe-right {
      right: ${theme.spacing(0.5)};
    }
`
);

const SwiperWrapper = styled(Box)(
  ({ theme }) => `
  .swiper-wrapper {
    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 400px;
        height: 400px;
      }
    }
  }

  .swiper-container-thumbs {
    .swiper-wrapper {
      display: flex;
      align-items: center;
    }

    .swiper-slide {
      width: 140px;
      display: flex;
      padding: 3px;

      img {
        width: 100%;
        height: auto;
        border-radius: ${theme.general.borderRadius};
        opacity: .7;
        transition: ${theme.transitions.create(['box-shadow', 'opacity'])};
      }

      &:hover {
        cursor: pointer;

        img {
          opacity: 1;
        }
      }

      &.swiper-slide-thumb-active {
        img {
          opacity: 1;
          box-shadow: 0 0 0 3px ${theme.colors.primary.main};
        }
      }
    }
  }
`
);

const TabsContainerWrapper = styled(Box)(
  ({ theme }) => `
      background-color: ${theme.colors.alpha.black[5]};
      padding: ${theme.spacing(2)};
  `
);

const ProductBody: FC<ProductBodyProps> = ({ product }) => {
  const { t }: { t: any } = useTranslation();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const { enqueueSnackbar } = useSnackbar();

  const [option1, setOption1] = useState('10');

  const handleChange1 = (event: ChangeEvent<HTMLInputElement>): void => {
    setOption1(event.target.value);
  };

  const [option2, setOption2] = useState('20');

  const handleChange2 = (event: ChangeEvent<HTMLInputElement>): void => {
    setOption2(event.target.value);
  };

  const [currentTab, setCurrentTab] = useState<string>('reviews');

  const tabs = [
    { value: 'reviews', label: t('Reviews') },
    { value: 'additional_info', label: t('Additional Informations') }
  ];

  const handleTabsChange = (_event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
  };

  const handleCart = () => {
    enqueueSnackbar(t('You added a product to cart'), {
      variant: 'success',
      preventDuplicate: false,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center'
      },
      TransitionComponent: Slide
    });
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      spacing={mobile ? 1 : 3}
    >
      <Grid item xs={12}>
        <Card>
          <Grid container spacing={0}>
            <Grid
              xs={12}
              md={4}
              item
              sx={{
                position: 'relative'
              }}
            >
              <Box
                component="span"
                sx={{
                  display: { xs: 'none', md: 'inline-block' }
                }}
              >
                <Divider
                  absolute
                  sx={{
                    height: '100%',
                    left: 'auto',
                    right: 0
                  }}
                  orientation="vertical"
                  flexItem
                />
              </Box>
              <Box p={3} width={'100%'} textAlign={'center'}>
                <img
                  src={cdnService.getImageByForCategoryName(
                    product.surveyGroup.surveyProductCategory
                  )}
                  alt="..."
                  onError={(e: any) => {
                    e.target.src = cdnService.noSurveyImage;
                  }}
                  width={400}
                  height={400}
                />
                {/* <SwiperWrapper>
                  <Box
                    mb={mobile ? 0 : 3}
                    sx={{
                      position: 'relative'
                    }}
                  >
                    <Swiper
                      loop
                      autoHeight
                      spaceBetween={2}
                      thumbs={{ swiper: thumbsSwiper }}
                      modules={[FreeMode, Navigation, Thumbs]}
                      navigation={{
                        nextEl: '.MuiSwipe-right',
                        prevEl: '.MuiSwipe-left'
                      }}
                    >
                      <SwiperSlide>
                        <img
                          src={cdnService.getImageByForCategoryName(
                            product.surveyGroup.surveyProductCategory
                          )}
                          alt="..."
                          onError={(e: any) => {
                            e.target.src = cdnService.noSurveyImage;
                          }}
                          width={400}
                          height={400}
                        />
                      </SwiperSlide>
                    </Swiper>
                    <SwipeIndicator className="MuiSwipe-root MuiSwipe-left">
                      <ChevronLeftTwoToneIcon />
                    </SwipeIndicator>
                    <SwipeIndicator className="MuiSwipe-root MuiSwipe-right">
                      <ChevronRightTwoToneIcon />
                    </SwipeIndicator>
                  </Box>
                </SwiperWrapper> */}
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Box p={mobile ? 2 : 4} flex={1}>
                <Rating readOnly defaultValue={5} precision={0.5} />
                <Typography
                  variant="h3"
                  sx={{
                    pb: 2,
                    pt: 1
                  }}
                  component="h3"
                >
                  {product.surveyName}
                </Typography>
                <Typography variant="subtitle2">
                  <div
                    dangerouslySetInnerHTML={{ __html: product.surveyDesc }}
                  />
                </Typography>
                <Divider
                  sx={{
                    mt: 3
                  }}
                />

                {/* <Typography
                  variant="h5"
                  sx={{
                    pb: 1
                  }}
                >
                  {t('Share')}
                </Typography>
                <Button
                  sx={{
                    mr: 1
                  }}
                  variant="outlined"
                  size="small"
                >
                  Facebook
                </Button>
                <Button
                  sx={{
                    mr: 1
                  }}
                  variant="outlined"
                  size="small"
                >
                  Twitter
                </Button> */}
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      {/* <Grid item xs={12}>
        <Card>
          <TabsContainerWrapper>
            <Tabs
              onChange={handleTabsChange}
              value={currentTab}
              variant="scrollable"
              scrollButtons="auto"
              textColor="primary"
              indicatorColor="primary"
            >
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
          </TabsContainerWrapper>
          <Divider />
          {currentTab === 'reviews' && <ReviewsTab />}
          {currentTab === 'additional_info' && <AdditionalInfoTab />}
        </Card>
      </Grid> */}
    </Grid>
  );
};

ProductBody.propTypes = {
  // @ts-ignore
  product: PropTypes.object.isRequired
};

export default ProductBody;
